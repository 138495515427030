@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, .layout {
  height: 100%;
  margin: 0;
 
  
}

.layout {
  position: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the layout takes up the full viewport height */
  width: 100vw; 
}

header {
  position: sticky; 
  top: 0; 
  z-index: 1000; 
  background-color: #f8f8f8; 
  padding: 1rem;
  max-height: 40vh;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a shadow to the header */
}

.content {
  flex: 1;   /* Takes up the remaining space between header and footer */
  width: 100%;
  overflow-y: auto;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
  max-height: 20vh;
}
.auth-text {
  font-family: 'Cinzel', serif;
  font-weight: 400;
}

.date-text {
  font-family: 'Cinzel', serif;
  font-style: italic;
}
